// 放置所有的请求接口地址
// const getRealData = 'http://localhost:9006/snapi/device/getRealData'
// const suffix = '/snapi'

//正式库地址
const getRealData = '/es/device/getRealData'
const suffix = '/snapi'


const urls = class{
	static m(){
		// 登录接口
		const login = `${suffix}/oauth2/token`
		//const login = `${url}login`

		const getRealDataForChart = `${suffix}/device/getRealDataForChart`

		//文件上传
		const updateFileUrlOne = `${suffix}/common/commonUploadFile_uploadOne`
		//公共日志
		const commonLog_list = `${suffix}/common/commonLog_list`

		//首页数据
		const index_statData = `${suffix}/index_statData`
		const index_statData2 = `${suffix}/index_statData2`


		//数据字典
		const sysDict_saveOrUpdateItem = `${suffix}/sys/sysDict_saveOrUpdateItem`
		const sysDict_deleteItem = `${suffix}/sys/sysDict_deleteItem`
		const sysDict_listItem = `${suffix}/sys/sysDict_listItem`
		const sysDict_listItemByDictId = `${suffix}/sys/sysDict_listItemByDictId`
		const sysDict_listItemByCode = `${suffix}/sys/sysDict_listItemByCode`
		const sysDict_listItemByDesc = `${suffix}/sys/sysDict_listItemByDesc`
		const sysDict_listItemByDictIdAndDesc = `${suffix}/sys/sysDict_listItemByDictIdAndDesc`
		const sysDict_getForCode = `${suffix}/sys/sysDict_getForCode`
		const sysDict_list = `${suffix}/sys/sysDict_list`
		const sysDict_getDetail = `${suffix}/sys/sysDict_getDetail`
 
		//角色URL
		const sysRole_list = `${suffix}/sys/sysRole_list`
		const sysRole_save = `${suffix}/sys/sysRole_save`
		const sysRole_update = `${suffix}/sys/sysRole_update`
		const sysRole_delete = `${suffix}/sys/sysRole_delete`
		const sysRole_getDetail = `${suffix}/sys/sysRole_getDetail`
		const sysRole_listCom = `${suffix}/sys/sysRole_listCom`

		//日志列表
		const sysLog_list = `${suffix}/sys/sysLog_list`
		const sysLog_getDetail = `${suffix}/sys/sysLog_getDetail`
		//组织机构
		const sysDept_listCom = `${suffix}/sys/sysDept_listCom`
		const sysDept_list = `${suffix}/sys/sysDept_list`
		const sysDept_save = `${suffix}/sys/sysDept_save`
		const sysDept_update = `${suffix}/sys/sysDept_update`
		const sysDept_delete = `${suffix}/sys/sysDept_delete`
		const sysDept_getDetail = `${suffix}/sys/sysDept_getDetail`

		//用户列表
		const sysUser_list = `${suffix}/sys/sysUser_list`
		const sysUser_save = `${suffix}/sys/sysUser_save`
		const sysUser_delete = `${suffix}/sys/sysUser_delete`
		const sysUser_getDetail = `${suffix}/sys/sysUser_getDetail`
		const sysUser_updatePassword = `${suffix}/sys/sysUser_updatePassword`
		//反馈中心
		const appFeedback_list = `${suffix}/app/appFeedback_list`
		const appFeedback_getDetail = `${suffix}/app/appFeedback_getDetail`
		const appFeedback_save = `${suffix}/app/appFeedback_save`
		const appFeedback_delete = `${suffix}/app/appFeedback_delete`
		const appFeedback_updateCl = `${suffix}/app/appFeedback_updateCl`
		//任务管理task
		const task_list = `${suffix}/task/task_list`
		const task_getDetail = `${suffix}/task/task_getDetail`
		const task_delete = `${suffix}/task/task_delete` 
		const  task_updateSetRead = `${suffix}/task/task_updateSetRead`

		//股票
		const stockTransactionMine_list = `${suffix}/stock/stockTransactionMine_list`
		const stockTransactionMine_list_has = `${suffix}/stock/stockTransactionMine_list_has`
		const stockTransactionMine_getDetail = `${suffix}/stock/stockTransactionMine_getDetail`
		const stockTransactionMine_delete = `${suffix}/stock/stockTransactionMine_delete`
		const stockTransactionMine_save = `${suffix}/stock/stockTransactionMine_save`
		const stockTransactionMine_updateSell = `${suffix}/stock/stockTransactionMine_updateSell`
		const stockTransactionMine_getRecent = `${suffix}/stock/stockTransactionMine_getRecent`
		const stockTransactionMine_getNosellList = `${suffix}/stock/stockTransactionMine_getNosellList`
		const stockTransactionMine_getStat = `${suffix}/stock/stockTransactionMine_getStat`

		const stockTransactionImitate_list = `${suffix}/stock/stockTransactionImitate_list`
		const stockTransactionImitate_getDetail = `${suffix}/stock/stockTransactionImitate_getDetail`
		const stockTransactionImitate_delete = `${suffix}/stock/stockTransactionImitate_delete`
		const stockTransactionImitate_save = `${suffix}/stock/stockTransactionImitate_save`

		const stockHis_list = `${suffix}/stock/stockHis_list`
		const stockHis_getDetail = `${suffix}/stock/stockHis_getDetail`
		const stockHis_delete = `${suffix}/stock/stockHis_delete`
		const stockHis_save = `${suffix}/stock/stockHis_save`
		const stockHis_download = `${suffix}/stock/stockHis_download`

		const stockInfor_list = `${suffix}/stock/stockInfor_list`
		const stockInfor_getDetail = `${suffix}/stock/stockInfor_getDetail`
		const stockInfor_delete = `${suffix}/stock/stockInfor_delete`
		const stockInfor_save = `${suffix}/stock/stockInfor_save`
		const stockInfor_listCom = `${suffix}/stock/stockInfor_listCom`
		const stockInfor_listZs = `${suffix}/stock/stockInfor_listZs`
		//股票结果
		const stockMathResult_list = `${suffix}/stock/stockMathResult_list`
		const stockMathResult_getDetail = `${suffix}/stock/stockMathResult_getDetail`
		const stockMathResult_saveOrUpdate = `${suffix}/stock/stockMathResult_saveOrUpdate`
		const stockMathResult_delete = `${suffix}/stock/stockMathResult_delete`
		const stockMathResult_updateSh = `${suffix}/stock/stockMathResult_updateSh`
		const stockMathResult_importExcel = `${suffix}/stock/stockMathResult_importExcel`
		const stockMathResult_downImportModel = `${suffix}/stock/stockMathResult_downImportModel`
		const stockMathResult_exportExcel = `${suffix}/stock/stockMathResult_exportExcel`
		const stockMathResult_mathForAi = `${suffix}/stock/stockMathResult_mathForAi`

		const stockMathMain_list = `${suffix}/stock/stockMathMain_list`
		const stockMathMain_getDetail = `${suffix}/stock/stockMathMain_getDetail`
		const stockMathMain_delete = `${suffix}/stock/stockMathMain_delete`	
		

		//企业管理
		const company_list = `${suffix}/company/company_list`
		const company_getDetail = `${suffix}/company/company_getDetail`
		const company_saveOrUpdate = `${suffix}/company/company_saveOrUpdate`
		const company_delete = `${suffix}/company/company_delete`
		const company_updateSh = `${suffix}/company/company_updateSh`
		const company_importExcel = `${suffix}/company/company_importExcel`
		const company_downImportModel = `${suffix}/company/company_downImportModel`
		const company_exportExcel = `${suffix}/company/company_exportExcel`
		const company_listCom = `${suffix}/company/company_listCom`

		//参数设置
		const stockParams_list = `${suffix}/stock/stockParams_list`
		const stockParams_getDetail = `${suffix}/stock/stockParams_getDetail`
		const stockParams_saveOrUpdate = `${suffix}/stock/stockParams_saveOrUpdate`

 
		//权限相关
		const sysPower_getRoleModulesTree = `${suffix}/sys/sysPower_getRoleModulesTree`
		const sysPower_saveRoleModules = `${suffix}/sys/sysPower_saveRoleModules`
		const sysPower_saveUserRoles = `${suffix}/sys/sysPower_saveUserRoles`
		//用户角色选择初始化
		const sysUser_getUserRolesTree = `${suffix}/sys/sysUser_getUserRolesTree`
		//用户详情
		const getmessage = `${suffix}/index/getmessage`
		//deptId查询页面
		const sysdept_listWeb = `/index_new.html`


		//权限管理
		const menu_getTopMenuVue = `${suffix}/management/menu/menu_getTopMenuVue`
		const menuByUser = `${suffix}/index1/index_statData`

		//设备控制器版本管理
		const deviceVersion_list = `${suffix}/device/deviceVersion_list`
		const deviceVersion_getDetail = `${suffix}/device/deviceVersion_getDetail`
		const deviceVersion_saveOrUpdate = `${suffix}/device/deviceVersion_saveOrUpdate`
		const deviceVersion_delete = `${suffix}/device/deviceVersion_delete`
		const deviceVersion_exportExcel = `${suffix}/device/deviceVersion_exportExcel`
		const deviceVersionLog_list = `${suffix}/device/deviceVersionLog_list`
		const deviceVersionLog_exportExcel = `${suffix}/device/deviceVersionLog_exportExcel`

		const studentInfor_list = `${suffix}/student/studentInfor_list`
		const studentInfor_getDetail = `${suffix}/student/studentInfor_getDetail`
		const studentInfor_saveOrUpdate = `${suffix}/student/studentInfor_saveOrUpdate`
		const studentInfor_delete = `${suffix}/student/studentInfor_delete`
		const studentInfor_updateSh = `${suffix}/student/studentInfor_updateSh`
		const studentInfor_importExcel = `${suffix}/student/studentInfor_importExcel`
		const studentInfor_downImportModel = `${suffix}/student/studentInfor_downImportModel`
		const studentInfor_exportExcel = `${suffix}/student/studentInfor_exportExcel`


		//售后申请
		const deviceAfterSalesApply_list = `${suffix}/device/deviceAfterSalesApply_list`
		const deviceAfterSalesApply_getDetail = `${suffix}/device/deviceAfterSalesApply_getDetail`
		const deviceAfterSalesApply_saveOrUpdate = `${suffix}/device/deviceAfterSalesApply_saveOrUpdate`
		const deviceAfterSalesApply_delete = `${suffix}/device/deviceAfterSalesApply_delete`
		const deviceAfterSalesApply_updateSh = `${suffix}/device/deviceAfterSalesApply_updateSh`
		const deviceAfterSalesApply_importExcel = `${suffix}/device/deviceAfterSalesApply_importExcel`
		const deviceAfterSalesApply_downImportModel = `${suffix}/device/deviceAfterSalesApply_downImportModel`
		const deviceAfterSalesApply_exportExcel = `${suffix}/device/deviceAfterSalesApply_exportExcel`
		const deviceAfterSalesRecord_updateDeal = `${suffix}/device/deviceAfterSalesRecord_updateDeal`
		const deviceAfterSalesApply_listNoDealData = `${suffix}/device/deviceAfterSalesApply_listNoDealData`
 
		//砂浆罐运行状态
		const deviceInforState_list = `${suffix}/device/deviceInforState_list`
		const deviceInforState_getDetail = `${suffix}/device/deviceInforState_getDetail`
		const deviceInforState_saveOrUpdate = `${suffix}/device/deviceInforState_saveOrUpdate`
		const deviceInforState_delete = `${suffix}/device/deviceInforState_delete`
		const deviceInforState_updateSh = `${suffix}/device/deviceInforState_updateSh`
		const deviceInforState_importExcel = `${suffix}/device/deviceInforState_importExcel`
		const deviceInforState_downImportModel = `${suffix}/device/deviceInforState_downImportModel`
		const deviceInforState_exportExcel = `${suffix}/device/deviceInforState_exportExcel`
		//砂浆罐信息
		const deviceInfor_list = `${suffix}/device/deviceInfor_list`
		const deviceInfor_getDetail = `${suffix}/device/deviceInfor_getDetail`
		const deviceInfor_getAllDetail = `${suffix}/device/deviceInfor_getAllDetail`
		const deviceInfor_getUseAndPutLog = `${suffix}/device/deviceInfor_getUseAndPutLog`
		const deviceInfor_getUseAndPutChart = `${suffix}/device/deviceInfor_getUseAndPutChart`
		const deviceInfor_saveOrUpdate = `${suffix}/device/deviceInfor_saveOrUpdate`
		const deviceInfor_saveMore = `${suffix}/device/deviceInfor_saveMore`

		
		const deviceInfor_delete = `${suffix}/device/deviceInfor_delete`
		const deviceInfor_getSum = `${suffix}/device/deviceInfor_getSum`
		const deviceInfor_saveMigrate = `${suffix}/device/deviceInfor_saveMigrate`
		const deviceInfor_importExcel = `${suffix}/device/deviceInfor_importExcel`
		const deviceInfor_downImportModel = `${suffix}/device/deviceInfor_downImportModel`
		const deviceInfor_exportExcel = `${suffix}/device/deviceInfor_exportExcel`
		const deviceInfor_getAuto = `${suffix}/device/deviceInfor_getAuto`
		const deviceInfor_updateSetZt = `${suffix}/device/deviceInfor_updateSetZt`
		const deviceInfor_updateSetParam = `${suffix}/device/deviceInfor_updateSetParam`
		const deviceInfor_curveData = `${suffix}/device/deviceInfor_curveData`
		const deviceInfor_updateBatch = `${suffix}/device/deviceInfor_updateBatch`
		const deviceInfor_updateBatchRestart = `${suffix}/device/deviceInfor_updateBatchRestart`

		//迁移IP
		const deviceIps_list = `${suffix}/device/deviceIps_list`
		const deviceIps_getDetail = `${suffix}/device/deviceIps_getDetail`
		const deviceIps_saveOrUpdate = `${suffix}/device/deviceIps_saveOrUpdate`
		const deviceIps_delete = `${suffix}/device/deviceIps_delete`
		const deviceIps_updateSh = `${suffix}/device/deviceIps_updateSh`
		const deviceIps_importExcel = `${suffix}/device/deviceIps_importExcel`
		const deviceIps_downImportModel = `${suffix}/device/deviceIps_downImportModel`
		const deviceIps_exportExcel = `${suffix}/device/deviceIps_exportExcel`
		
		//砂浆罐连接日志
		const deviceConLog_list = `${suffix}/device/deviceConLog_list`
		const deviceConLog_getDetail = `${suffix}/device/deviceConLog_getDetail`
		const deviceConLog_saveOrUpdate = `${suffix}/device/deviceConLog_saveOrUpdate`
		const deviceConLog_delete = `${suffix}/device/deviceConLog_delete`
		const deviceConLog_updateSh = `${suffix}/device/deviceConLog_updateSh`
		const deviceConLog_importExcel = `${suffix}/device/deviceConLog_importExcel`
		const deviceConLog_downImportModel = `${suffix}/device/deviceConLog_downImportModel`
		const deviceConLog_exportExcel = `${suffix}/device/deviceConLog_exportExcel`

		//操作记录
		const deviceOpLog_list = `${suffix}/device/deviceOpLog_list`
		const deviceOpLog_getDetail = `${suffix}/device/deviceOpLog_getDetail`
		const deviceOpLog_saveOrUpdate = `${suffix}/device/deviceOpLog_saveOrUpdate`
		const deviceOpLog_delete = `${suffix}/device/deviceOpLog_delete`
		const deviceOpLog_updateSh = `${suffix}/device/deviceOpLog_updateSh`
		const deviceOpLog_importExcel = `${suffix}/device/deviceOpLog_importExcel`
		const deviceOpLog_downImportModel = `${suffix}/device/deviceOpLog_downImportModel`
		const deviceOpLog_exportExcel = `${suffix}/device/deviceOpLog_exportExcel`
		const deviceOpLog_getIndexData = `${suffix}/device/deviceOpLog_getIndexData`

		
		//砂浆罐入库记录
		const devicePutLog_list = `${suffix}/device/devicePutLog_list`
		const devicePutLog_getDetail = `${suffix}/device/devicePutLog_getDetail`
		const devicePutLog_saveOrUpdate = `${suffix}/device/devicePutLog_saveOrUpdate`
		const devicePutLog_delete = `${suffix}/device/devicePutLog_delete`
		const devicePutLog_updateSh = `${suffix}/device/devicePutLog_updateSh`
		const devicePutLog_importExcel = `${suffix}/device/devicePutLog_importExcel`
		const devicePutLog_downImportModel = `${suffix}/device/devicePutLog_downImportModel`
		const devicePutLog_exportExcel = `${suffix}/device/devicePutLog_exportExcel`

		
		//DeviceSimState
		const deviceSimState_list = `${suffix}/device/deviceSimState_list`
		const deviceSimState_getDetail = `${suffix}/device/deviceSimState_getDetail`
		const deviceSimState_saveOrUpdate = `${suffix}/device/deviceSimState_saveOrUpdate`
		const deviceSimState_delete = `${suffix}/device/deviceSimState_delete`
		const deviceSimState_updateSh = `${suffix}/device/deviceSimState_updateSh`
		const deviceSimState_importExcel = `${suffix}/device/deviceSimState_importExcel`
		const deviceSimState_downImportModel = `${suffix}/device/deviceSimState_downImportModel`
		const deviceSimState_exportExcel = `${suffix}/device/deviceSimState_exportExcel`

		//砂浆罐迁移记录
		const deviceTransfer_list = `${suffix}/device/deviceTransfer_list`
		const deviceTransfer_getDetail = `${suffix}/device/deviceTransfer_getDetail`
		const deviceTransfer_saveOrUpdate = `${suffix}/device/deviceTransfer_saveOrUpdate`
		const deviceTransfer_delete = `${suffix}/device/deviceTransfer_delete`
		const deviceTransfer_updateSh = `${suffix}/device/deviceTransfer_updateSh`
		const deviceTransfer_importExcel = `${suffix}/device/deviceTransfer_importExcel`
		const deviceTransfer_downImportModel = `${suffix}/device/deviceTransfer_downImportModel`
		const deviceTransfer_exportExcel = `${suffix}/device/deviceTransfer_exportExcel`

		//DeviceUseLog
		const deviceUseLog_list = `${suffix}/device/deviceUseLog_list`
		const deviceUseLog_getDetail = `${suffix}/device/deviceUseLog_getDetail`
		const deviceUseLog_saveOrUpdate = `${suffix}/device/deviceUseLog_saveOrUpdate`
		const deviceUseLog_delete = `${suffix}/device/deviceUseLog_delete`
		const deviceUseLog_updateSh = `${suffix}/device/deviceUseLog_updateSh`
		const deviceUseLog_importExcel = `${suffix}/device/deviceUseLog_importExcel`
		const deviceUseLog_downImportModel = `${suffix}/device/deviceUseLog_downImportModel`
		const deviceUseLog_exportExcel = `${suffix}/device/deviceUseLog_exportExcel`

		//DeviceVariable
		const deviceVariable_list = `${suffix}/device/deviceVariable_list`
		const deviceVariable_getList = `${suffix}/device/deviceVariable_getList`
		const deviceVariable_getDetail = `${suffix}/device/deviceVariable_getDetail`
		const deviceVariable_saveOrUpdate = `${suffix}/device/deviceVariable_saveOrUpdate`
		const deviceVariable_delete = `${suffix}/device/deviceVariable_delete`
		const deviceVariable_updateSh = `${suffix}/device/deviceVariable_updateSh`
		const deviceVariable_importExcel = `${suffix}/device/deviceVariable_importExcel`
		const deviceVariable_downImportModel = `${suffix}/device/deviceVariable_downImportModel`
		const deviceVariable_exportExcel = `${suffix}/device/deviceVariable_exportExcel`

		//DeviceWljl
		const deviceWljl_list = `${suffix}/device/deviceWljl_list`
		const deviceWljl_getDetail = `${suffix}/device/deviceWljl_getDetail`
		const deviceWljl_saveOrUpdate = `${suffix}/device/deviceWljl_saveOrUpdate`
		const deviceWljl_delete = `${suffix}/device/deviceWljl_delete`
		const deviceWljl_updateSh = `${suffix}/device/deviceWljl_updateSh`
		const deviceWljl_importExcel = `${suffix}/device/deviceWljl_importExcel`
		const deviceWljl_downImportModel = `${suffix}/device/deviceWljl_downImportModel`
		const deviceWljl_exportExcel = `${suffix}/device/deviceWljl_exportExcel`


		//车货运记录
		const deviceCarRecord_list = `${suffix}/device/deviceCarRecord_list`
		const deviceCarRecord_getDetail = `${suffix}/device/deviceCarRecord_getDetail`
		const deviceCarRecord_saveOrUpdate = `${suffix}/device/deviceCarRecord_saveOrUpdate`
		const deviceCarRecord_delete = `${suffix}/device/deviceCarRecord_delete`
		const deviceCarRecord_updateSh = `${suffix}/device/deviceCarRecord_updateSh`
		const deviceCarRecord_importExcel = `${suffix}/device/deviceCarRecord_importExcel`
		const deviceCarRecord_downImportModel = `${suffix}/device/deviceCarRecord_downImportModel`
		const deviceCarRecord_exportExcel = `${suffix}/device/deviceCarRecord_exportExcel`


		//报警类型
		const deviceAlarmType_list = `${suffix}/device/deviceAlarmType_list`
		const deviceAlarmType_getDetail = `${suffix}/device/deviceAlarmType_getDetail`
		const deviceAlarmType_saveOrUpdate = `${suffix}/device/deviceAlarmType_saveOrUpdate`
		const deviceAlarmType_delete = `${suffix}/device/deviceAlarmType_delete`
		const deviceAlarmType_updateSh = `${suffix}/device/deviceAlarmType_updateSh`
		const deviceAlarmType_importExcel = `${suffix}/device/deviceAlarmType_importExcel`
		const deviceAlarmType_downImportModel = `${suffix}/device/deviceAlarmType_downImportModel`
		const deviceAlarmType_exportExcel = `${suffix}/device/deviceAlarmType_exportExcel`

		//报警记录
		const deviceAlarm_list = `${suffix}/device/deviceAlarm_list`
		const deviceAlarm_getDetail = `${suffix}/device/deviceAlarm_getDetail`
		const deviceAlarm_updateCz = `${suffix}/device/deviceAlarm_updateCz`
		const deviceAlarm_delete = `${suffix}/device/deviceAlarm_delete`
		const deviceAlarm_updateSh = `${suffix}/device/deviceAlarm_updateSh`
		const deviceAlarm_importExcel = `${suffix}/device/deviceAlarm_importExcel`
		const deviceAlarm_downImportModel = `${suffix}/device/deviceAlarm_downImportModel`
		const deviceAlarm_exportExcel = `${suffix}/device/deviceAlarm_exportExcel`


		
		//Product
		const product_list = `${suffix}/device/product_list`
		const product_listCom = `${suffix}/device/product_listCom`
		const product_listAll = `${suffix}/device/product_listAll`
		const product_getDetail = `${suffix}/device/product_getDetail`
		const product_saveOrUpdate = `${suffix}/device/product_saveOrUpdate`
		const product_delete = `${suffix}/device/product_delete`
		const product_updateSh = `${suffix}/device/product_updateSh`
		const product_importExcel = `${suffix}/device/product_importExcel`
		const product_downImportModel = `${suffix}/device/product_downImportModel`
		const product_exportExcel = `${suffix}/device/product_exportExcel`
		
		//DeviceWlpRef
		const deviceWlpRef_list = `${suffix}/device/deviceWlpRef_list`
		const deviceWlpRef_getDetail = `${suffix}/device/deviceWlpRef_getDetail`
		const deviceWlpRef_saveOrUpdate = `${suffix}/device/deviceWlpRef_saveOrUpdate`
		const deviceWlpRef_delete = `${suffix}/device/deviceWlpRef_delete`
		const deviceWlpRef_updateSh = `${suffix}/device/deviceWlpRef_updateSh`
		const deviceWlpRef_importExcel = `${suffix}/device/deviceWlpRef_importExcel`
		const deviceWlpRef_downImportModel = `${suffix}/device/deviceWlpRef_downImportModel`
		const deviceWlpRef_exportExcel = `${suffix}/device/deviceWlpRef_exportExcel`

		//DeviceWlp
		const deviceWlp_list = `${suffix}/device/deviceWlp_list`
		const deviceWlp_getDetail = `${suffix}/device/deviceWlp_getDetail`
		const deviceWlp_saveOrUpdate = `${suffix}/device/deviceWlp_saveOrUpdate`
		const deviceWlp_delete = `${suffix}/device/deviceWlp_delete`
		const deviceWlp_updateSh = `${suffix}/device/deviceWlp_updateSh`
		const deviceWlp_importExcel = `${suffix}/device/deviceWlp_importExcel`
		const deviceWlp_downImportModel = `${suffix}/device/deviceWlp_downImportModel`
		const deviceWlp_exportExcel = `${suffix}/device/deviceWlp_exportExcel`

		//企业用户
		const companyUser_list = `${suffix}/company/companyUser_list`
		const companyUser_getDetail = `${suffix}/company/companyUser_getDetail`
		const companyUser_saveOrUpdate = `${suffix}/company/companyUser_saveOrUpdate`
		const companyUser_delete = `${suffix}/company/companyUser_delete`
		const companyUser_updateSh = `${suffix}/company/companyUser_updateSh`
		const companyUser_importExcel = `${suffix}/company/companyUser_importExcel`
		const companyUser_downImportModel = `${suffix}/company/companyUser_downImportModel`
		const companyUser_exportExcel = `${suffix}/company/companyUser_exportExcel`
		const company_getTreeNode = `${suffix}/company/company_getTreeNode`

 
		const stockSelect_list = `${suffix}/stock/stockSelect_list`
		const stockSelect_delete = `${suffix}/stock/stockSelect_delete`
 
  
		//已经销售监控
		const stockHasSaled_list = `${suffix}/stock/stockHasSaled_list`
		const stockHasSaled_getDetail = `${suffix}/stock/stockHasSaled_getDetail`
		const stockHasSaled_saveOrUpdate = `${suffix}/stock/stockHasSaled_saveOrUpdate`
		const stockHasSaled_delete = `${suffix}/stock/stockHasSaled_delete`
		const stockHasSaled_updateSh = `${suffix}/stock/stockHasSaled_updateSh`
		const stockHasSaled_importExcel = `${suffix}/stock/stockHasSaled_importExcel`
		const stockHasSaled_downImportModel = `${suffix}/stock/stockHasSaled_downImportModel`
		const stockHasSaled_exportExcel = `${suffix}/stock/stockHasSaled_exportExcel`
		//养殖记录
		const breedRecord_list = `${suffix}/breed/breedRecord_list`
		const breedRecord_getDetail = `${suffix}/breed/breedRecord_getDetail`
		const breedRecord_getALLDetail = `${suffix}/breed/breedRecord_getALLDetail`
		const breedRecord_saveOrUpdate = `${suffix}/breed/breedRecord_saveOrUpdate`
		const breedRecord_delete = `${suffix}/breed/breedRecord_delete`
		const breedRecord_updateSh = `${suffix}/breed/breedRecord_updateSh`
		const breedRecord_importExcel = `${suffix}/breed/breedRecord_importExcel`
		const breedRecord_downImportModel = `${suffix}/breed/breedRecord_downImportModel`
		const breedRecord_exportExcel = `${suffix}/breed/breedRecord_exportExcel`
		const breedRecord_updateCom = `${suffix}/breed/breedRecord_updateCom`
		const breedRecord_updateStandard = `${suffix}/breed/breedRecord_updateStandard`
		//实时库配置
		const realConfig_list = `${suffix}/device/realConfig_list`
		const realConfig_getDetail = `${suffix}/device/realConfig_getDetail`
		const realConfig_saveOrUpdate = `${suffix}/device/realConfig_saveOrUpdate`
		const realConfig_delete = `${suffix}/device/realConfig_delete`
		const realConfig_updateSh = `${suffix}/device/realConfig_updateSh`
		const realConfig_importExcel = `${suffix}/device/realConfig_importExcel`
		const realConfig_downImportModel = `${suffix}/device/realConfig_downImportModel`
		const realConfig_exportExcel = `${suffix}/device/realConfig_exportExcel`

		//统计分析
		const tjfxOnlineState_list = `${suffix}/tjfx/tjfxOnlineState_list`
		const tjfxOnlineState_getCurveData = `${suffix}/tjfx/tjfxOnlineState_getCurveData`
		const tjfx_getDeviceFl = `${suffix}/tjfx/tjfx_getDeviceFl`

		//喂养标准
		const breedStandard_list = `${suffix}/breed/breedStandard_list`
		const breedStandard_getDetail = `${suffix}/breed/breedStandard_getDetail`
		const breedStandard_saveOrUpdate = `${suffix}/breed/breedStandard_saveOrUpdate`
		const breedStandard_delete = `${suffix}/breed/breedStandard_delete`
		const breedStandard_updateSh = `${suffix}/breed/breedStandard_updateSh`
		const breedStandard_importExcel = `${suffix}/breed/breedStandard_importExcel`
		const breedStandard_downImportModel = `${suffix}/breed/breedStandard_downImportModel`
		const breedStandard_exportExcel = `${suffix}/breed/breedStandard_exportExcel`
		const breedStandard_getUserData = `${suffix}/breed/breedStandard_getUserData`
		//死淘记录
		const breedRecordDie_list = `${suffix}/breed/breedRecordDie_list`
		const breedRecordDie_getDetail = `${suffix}/breed/breedRecordDie_getDetail`
		const breedRecordDie_saveOrUpdate = `${suffix}/breed/breedRecordDie_saveOrUpdate`
		const breedRecordDie_delete = `${suffix}/breed/breedRecordDie_delete`
		const breedRecordDie_updateSh = `${suffix}/breed/breedRecordDie_updateSh`
		const breedRecordDie_importExcel = `${suffix}/breed/breedRecordDie_importExcel`
		const breedRecordDie_downImportModel = `${suffix}/breed/breedRecordDie_downImportModel`
		const breedRecordDie_exportExcel = `${suffix}/breed/breedRecordDie_exportExcel`
		//喂养标准
		const breedStandardItem_list = `${suffix}/breed/breedStandardItem_list`
		const breedStandardItem_getDetail = `${suffix}/breed/breedStandardItem_getDetail`
		const breedStandardItem_saveOrUpdate = `${suffix}/breed/breedStandardItem_saveOrUpdate`
		const breedStandardItem_delete = `${suffix}/breed/breedStandardItem_delete`
		const breedStandardItem_updateSh = `${suffix}/breed/breedStandardItem_updateSh`
		const breedStandardItem_importExcel = `${suffix}/breed/breedStandardItem_importExcel`
		const breedStandardItem_downImportModel = `${suffix}/breed/breedStandardItem_downImportModel`
		const breedStandardItem_exportExcel = `${suffix}/breed/breedStandardItem_exportExcel`
		//喂养重量上报
		const breedWeight_list = `${suffix}/breed/breedWeight_list`
		const breedWeight_getDetail = `${suffix}/breed/breedWeight_getDetail`
		const breedWeight_saveOrUpdate = `${suffix}/breed/breedWeight_saveOrUpdate`
		const breedWeight_delete = `${suffix}/breed/breedWeight_delete`
		const breedWeight_updateSh = `${suffix}/breed/breedWeight_updateSh`
		const breedWeight_importExcel = `${suffix}/breed/breedWeight_importExcel`
		const breedWeight_downImportModel = `${suffix}/breed/breedWeight_downImportModel`
		const breedWeight_exportExcel = `${suffix}/breed/breedWeight_exportExcel`
		//喂养检查记录
		const breedCheckLog_list = `${suffix}/breed/breedCheckLog_list`
		const breedCheckLog_getDetail = `${suffix}/breed/breedCheckLog_getDetail`
		const breedCheckLog_saveOrUpdate = `${suffix}/breed/breedCheckLog_saveOrUpdate`
		const breedCheckLog_delete = `${suffix}/breed/breedCheckLog_delete`
		const breedCheckLog_updateSh = `${suffix}/breed/breedCheckLog_updateSh`
		const breedCheckLog_importExcel = `${suffix}/breed/breedCheckLog_importExcel`
		const breedCheckLog_downImportModel = `${suffix}/breed/breedCheckLog_downImportModel`
		const breedCheckLog_exportExcel = `${suffix}/breed/breedCheckLog_exportExcel`
		//日记录
		const breedRecordDay_list = `${suffix}/breed/breedRecordDay_list`
		const breedRecordDay_getDetail = `${suffix}/breed/breedRecordDay_getDetail`
		const breedRecordDay_saveOrUpdate = `${suffix}/breed/breedRecordDay_saveOrUpdate`
		const breedRecordDay_delete = `${suffix}/breed/breedRecordDay_delete`
		const breedRecordDay_updateSh = `${suffix}/breed/breedRecordDay_updateSh`
		const breedRecordDay_importExcel = `${suffix}/breed/breedRecordDay_importExcel`
		const breedRecordDay_downImportModel = `${suffix}/breed/breedRecordDay_downImportModel`
		const breedRecordDay_exportExcel = `${suffix}/breed/breedRecordDay_exportExcel`

		const breedRecordDie_getCurvePie = `${suffix}/breed/breedRecordDie_getCurvePie`
		const breedRecordDay_getCurve = `${suffix}/breed/breedRecordDay_getCurve`
		const breedRecordDay_getCurve2 = `${suffix}/breed/breedRecordDay_getCurve2`
 
		return{
			login,
			getRealData,
			getRealDataForChart,
			index_statData,
			index_statData2,
			commonLog_list,
			updateFileUrlOne,
			sysLog_list,
			sysLog_getDetail,
			sysRole_list,
			sysRole_save,
			sysRole_update,
			sysRole_listCom,
			sysRole_delete,
			sysRole_getDetail,
			sysDept_list,
			sysDept_save,
			sysDept_update,
			sysDept_delete,
			sysDept_getDetail,
			sysDept_listCom,
			sysUser_list,
			sysUser_save,
			sysUser_delete,
			sysUser_getDetail,
			sysUser_updatePassword,
			sysPower_saveUserRoles,
			sysUser_getUserRolesTree,
			getmessage,
			menu_getTopMenuVue,
			menuByUser,
			sysdept_listWeb,
			sysPower_getRoleModulesTree,
			sysPower_saveRoleModules,
			appFeedback_list,
			appFeedback_getDetail,
			appFeedback_save,
			appFeedback_delete,
			appFeedback_updateCl,
			task_list,
			task_getDetail,
			task_delete,
			task_updateSetRead,
			stockHis_delete,
			stockHis_list,
			stockHis_getDetail,
			stockHis_save,
			stockHis_download,
			stockTransactionImitate_list,
			stockTransactionImitate_getDetail,
			stockTransactionImitate_delete,
			stockTransactionImitate_save,
			stockTransactionMine_list,
			stockTransactionMine_list_has,
			stockTransactionMine_getDetail,
			stockTransactionMine_delete,
			stockTransactionMine_save,
			stockTransactionMine_updateSell,
			stockTransactionMine_getRecent,
			stockTransactionMine_getStat,
			stockInfor_list,
			stockInfor_getDetail,
			stockInfor_delete,
			stockInfor_listCom,
			stockInfor_save,
			stockMathResult_list,
			stockMathResult_getDetail,
			stockMathResult_saveOrUpdate,
			stockMathResult_delete,
			stockMathResult_updateSh,
			stockMathResult_importExcel,
			stockMathResult_downImportModel,
			stockMathResult_exportExcel,
			stockMathResult_mathForAi,
			stockMathMain_list,
			stockMathMain_getDetail,
			stockMathMain_delete,
			stockTransactionMine_getNosellList,
			company_list,
			company_getDetail,
			company_saveOrUpdate,
			company_delete,
			company_updateSh,
			company_importExcel,
			company_downImportModel,
			company_exportExcel,
			company_listCom,
			stockParams_list,
			stockParams_getDetail,
			stockParams_saveOrUpdate,
			deviceVersion_list,
			deviceVersion_getDetail,
			deviceVersion_saveOrUpdate,
			deviceVersion_delete,
			deviceVersion_exportExcel,
			deviceVersionLog_list,
			deviceVersionLog_exportExcel,
			studentInfor_list,
			studentInfor_getDetail,
			studentInfor_saveOrUpdate,
			studentInfor_delete,
			studentInfor_updateSh,
			studentInfor_importExcel,
			studentInfor_downImportModel,
			studentInfor_exportExcel,
			deviceAfterSalesApply_list,
			deviceAfterSalesApply_getDetail,
			deviceAfterSalesApply_saveOrUpdate,
			deviceAfterSalesApply_delete,
			deviceAfterSalesApply_updateSh,
			deviceAfterSalesApply_importExcel,
			deviceAfterSalesApply_downImportModel,
			deviceAfterSalesApply_exportExcel,
			deviceAfterSalesRecord_updateDeal,
			deviceAfterSalesApply_listNoDealData,
			deviceInforState_list,
			deviceInforState_getDetail,
			deviceInforState_saveOrUpdate,
			deviceInforState_delete,
			deviceInforState_updateSh,
			deviceInforState_importExcel,
			deviceInforState_downImportModel,
			deviceInforState_exportExcel,
			deviceInfor_list,
			deviceInfor_getDetail,
			deviceInfor_getAllDetail,
			deviceInfor_getUseAndPutLog,
			deviceInfor_getUseAndPutChart,
			deviceInfor_saveOrUpdate,
			deviceInfor_saveMore,
			deviceInfor_delete,
			deviceInfor_getSum,
			deviceInfor_saveMigrate,
			deviceInfor_importExcel,
			deviceInfor_downImportModel,
			deviceInfor_exportExcel,
			deviceInfor_curveData,
			deviceConLog_list,
			deviceConLog_getDetail,
			deviceConLog_saveOrUpdate,
			deviceConLog_delete,
			deviceConLog_updateSh,
			deviceConLog_importExcel,
			deviceConLog_downImportModel,
			deviceConLog_exportExcel,
			deviceOpLog_list,
			deviceOpLog_getDetail,
			deviceOpLog_saveOrUpdate,
			deviceOpLog_delete,
			deviceOpLog_updateSh,
			deviceOpLog_importExcel,
			deviceOpLog_downImportModel,
			deviceOpLog_exportExcel,
			deviceOpLog_getIndexData,
			devicePutLog_list,
			devicePutLog_getDetail,
			devicePutLog_saveOrUpdate,
			devicePutLog_delete,
			devicePutLog_updateSh,
			devicePutLog_importExcel,
			devicePutLog_downImportModel,
			devicePutLog_exportExcel,
			deviceSimState_list,
			deviceSimState_getDetail,
			deviceSimState_saveOrUpdate,
			deviceSimState_delete,
			deviceSimState_updateSh,
			deviceSimState_importExcel,
			deviceSimState_downImportModel,
			deviceSimState_exportExcel,
			deviceTransfer_list,
			deviceTransfer_getDetail,
			deviceTransfer_saveOrUpdate,
			deviceTransfer_delete,
			deviceTransfer_updateSh,
			deviceTransfer_importExcel,
			deviceTransfer_downImportModel,
			deviceTransfer_exportExcel,
			deviceUseLog_list,
			deviceUseLog_getDetail,
			deviceUseLog_saveOrUpdate,
			deviceUseLog_delete,
			deviceUseLog_updateSh,
			deviceUseLog_importExcel,
			deviceUseLog_downImportModel,
			deviceUseLog_exportExcel,
			deviceVariable_list,
			deviceVariable_getList,
			deviceVariable_getDetail,
			deviceVariable_saveOrUpdate,
			deviceVariable_delete,
			deviceVariable_updateSh,
			deviceVariable_importExcel,
			deviceVariable_downImportModel,
			deviceVariable_exportExcel,
			deviceWlp_list,
			deviceWlp_getDetail,
			deviceWlp_saveOrUpdate,
			deviceWlp_delete,
			deviceWlp_updateSh,
			deviceWlp_importExcel,
			deviceWlp_downImportModel,
			deviceWlp_exportExcel,
			deviceWlpRef_list,
			deviceWlpRef_getDetail,
			deviceWlpRef_saveOrUpdate,
			deviceWlpRef_delete,
			deviceWlpRef_updateSh,
			deviceWlpRef_importExcel,
			deviceWlpRef_downImportModel,
			deviceWlpRef_exportExcel,
			product_list,
			product_listCom,
			product_listAll,
			product_getDetail,
			product_saveOrUpdate,
			product_delete,
			product_updateSh,
			product_importExcel,
			product_downImportModel,
			product_exportExcel,
			deviceAlarm_list,
			deviceAlarm_getDetail,
			deviceAlarm_updateCz,
			deviceAlarm_delete,
			deviceAlarm_updateSh,
			deviceAlarm_importExcel,
			deviceAlarm_downImportModel,
			deviceAlarm_exportExcel,
			deviceAlarmType_list,
			deviceAlarmType_getDetail,
			deviceAlarmType_saveOrUpdate,
			deviceAlarmType_delete,
			deviceAlarmType_updateSh,
			deviceAlarmType_importExcel,
			deviceAlarmType_downImportModel,
			deviceAlarmType_exportExcel,
			deviceCarRecord_list,
			deviceCarRecord_getDetail,
			deviceCarRecord_saveOrUpdate,
			deviceCarRecord_delete,
			deviceCarRecord_updateSh,
			deviceCarRecord_importExcel,
			deviceCarRecord_downImportModel,
			deviceCarRecord_exportExcel,
			deviceWljl_list,
			deviceWljl_getDetail,
			deviceWljl_saveOrUpdate,
			deviceWljl_delete,
			deviceWljl_updateSh,
			deviceWljl_importExcel,
			deviceWljl_downImportModel,
			deviceWljl_exportExcel,
			deviceIps_list,
			deviceIps_getDetail,
			deviceIps_saveOrUpdate,
			deviceIps_delete,
			deviceIps_updateSh,
			deviceIps_importExcel,
			deviceIps_downImportModel,
			deviceIps_exportExcel,
			companyUser_list,
			companyUser_getDetail,
			companyUser_saveOrUpdate,
			companyUser_delete,
			companyUser_updateSh,
			companyUser_importExcel,
			companyUser_downImportModel,
			companyUser_exportExcel,
			company_getTreeNode,
			deviceInfor_getAuto,
			deviceInfor_updateSetParam,
			deviceInfor_updateSetZt,
			deviceInfor_updateBatch,
			deviceInfor_updateBatchRestart,
			stockSelect_list,
			stockSelect_delete,
			stockInfor_listZs,
			stockHasSaled_list,
			stockHasSaled_getDetail,
			stockHasSaled_saveOrUpdate,
			stockHasSaled_delete,
			stockHasSaled_updateSh,
			stockHasSaled_importExcel,
			stockHasSaled_downImportModel,
			stockHasSaled_exportExcel,
			breedRecord_list,
			breedRecord_getDetail,
			breedRecord_getALLDetail,
			breedRecord_saveOrUpdate,
			breedRecord_delete,
			breedRecord_updateSh,
			breedRecord_updateCom,
			breedRecord_updateStandard,
			breedRecord_importExcel,
			breedRecord_downImportModel,
			breedRecord_exportExcel,
			realConfig_list,
			realConfig_getDetail,
			realConfig_saveOrUpdate,
			realConfig_delete,
			realConfig_updateSh,
			realConfig_importExcel,
			realConfig_downImportModel,
			realConfig_exportExcel,
			sysDict_saveOrUpdateItem,
			sysDict_listItemByCode,
			sysDict_deleteItem,
			sysDict_listItem,
			sysDict_listItemByDictId,	
			tjfxOnlineState_list,
			tjfxOnlineState_getCurveData,
			tjfx_getDeviceFl,
			breedStandard_list,
			breedStandard_getDetail,
			breedStandard_saveOrUpdate,
			breedStandard_delete,
			breedStandard_updateSh,
			breedStandard_importExcel,
			breedStandard_downImportModel,
			breedStandard_exportExcel,
			breedStandard_getUserData,
			breedRecordDie_list,
			breedRecordDie_getDetail,
			breedRecordDie_saveOrUpdate,
			breedRecordDie_delete,
			breedRecordDie_updateSh,
			breedRecordDie_importExcel,
			breedRecordDie_downImportModel,
			breedRecordDie_exportExcel,
			breedStandardItem_list,
			breedStandardItem_getDetail,
			breedStandardItem_saveOrUpdate,
			breedStandardItem_delete,
			breedStandardItem_updateSh,
			breedStandardItem_importExcel,
			breedStandardItem_downImportModel,
			breedStandardItem_exportExcel,
			breedWeight_list,
			breedWeight_getDetail,
			breedWeight_saveOrUpdate,
			breedWeight_delete,
			breedWeight_updateSh,
			breedWeight_importExcel,
			breedWeight_downImportModel,
			breedWeight_exportExcel,
			breedRecordDay_list,
			breedRecordDay_getDetail,
			breedRecordDay_saveOrUpdate,
			breedRecordDay_delete,
			breedRecordDay_updateSh,
			breedRecordDay_importExcel,
			breedRecordDay_downImportModel,
			breedRecordDay_exportExcel,
			breedRecordDie_getCurvePie,
			breedRecordDay_getCurve,
			breedRecordDay_getCurve2
		}
	}
}

export default urls