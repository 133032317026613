import axios from 'axios'
import instance from './header.js'

const request = class{
  constructor(url,args){
        this.url =url;
        this.args = args;
  }
  //post
  modepost(){
    return new Promise((resolve,reject)=>{
        instance.post((this.url),this.args)
        .then(res=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
  }

  modeget(){
    return new Promise((resolve,reject)=>{
        instance.get((this.url))
        .then(res=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
  }

  modeParams(){
    return axios({
      method: 'post',
      url: this.url,
      headers: {
        'Content-Type': 'application/json'
      },
      params: this.args
    })

  }


}

export default request


 

