import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import ElementPlus from 'element-plus'
import axios from 'axios'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'


// 全局引入css
import './style/headtap.css'
// 全局引入css
import './style/normalize.css'
 
const app = createApp(App);
 
app.use(ElementPlus, {
    locale: zhCn,
  })
app.use(router)

//请求地址
import urls from './api/api.js'
app.config.globalProperties.$urls = urls;

import request from './api/request.js'
app.config.globalProperties.$request = request;

import tips from './config/Tips.js'
app.config.globalProperties.$tips = tips;

import confirms from './config/Confirms.js'
app.config.globalProperties.$confirms = confirms;

import config from './config/config.js'
app.config.globalProperties.$config = config;

import datas from './config/data.js'
app.config.globalProperties.$datas = datas;



// import {echarts} from "echarts"; 
// app.config.globalProperties.$echarts = echarts;
 
// axios.defaults.baseURL = 'http://127.0.0.1:9001'


// app.use(axios)
// Vue.prototype.axios = axios

app.mount("#app")

