import { ElMessage, ElMessageBox } from 'element-plus'


const confirms = class{
   constructor(title='请您确认',message,success,cancel=()=>{}){
       this.title = title;
       this.message = message;
       this.success=success;
       this.cancel=cancel;
   }
   open(){
    ElMessageBox.confirm(
        this.message,
        this.title,
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
            this.success()
        })
        .catch(() => {
            this.cancel()
        })
    }
}
export default confirms

