// 放置公共数据集合
const comData = {
	//标定
	LIST_BD:[
		{key:'未标定',text:'未标定'},
		{key:'粗略标定',text:'粗略标定'},
		{key:'精确标定',text:'精确标定'},
		{key:'空仓清零',text:'空仓清零'},
		{key:'标定完成',text:'标定完成'},
		{key:'传感器故障',text:'传感器故障'}
	],
	//筒仓系统类别
	LIST_TC_XTLB:[
		{key:1,text:'重锤料位单系统'},
		{key:2,text:'贴片料位单系统'},
		{key:3,text:'安保重锤全系统'},
		{key:4,text:'安保贴片全系统'},
		{key:5,text:'安保重锤+料位全系统'},
	],
	//物料牌系统类别
	LIST_WLP_XTLB:[
		{key:1,text:'贴片仪表料仓终端'},
		{key:4,text:'重锤料位计物料牌'},
		{key:5,text:'超声波液位计物料牌'},
		{key:6,text:'贴片赋存量物料牌'},
	],
	//上传倍数
	LIST_SCBS:[
		{key:-1.0,text:'默认'},
		{key:1.0,text:'无'},
		{key:0.1,text:'*0.1Kg'},
		{key:0.01,text:'*0.01Kg'},
	],
	//小数点
	LIST_XSD:[
		{key:-1,text:'自动'},
		{key:0,text:'无小数点'},
		{key:1,text:'1位小数点'},
		{key:2,text:'2位小数点'},
	],
	//单位
	LIST_DW:[
		{key:'千克',text:'千克'},
		{key:'吨',text:'吨'},
	],
	//容器类型
	LIST_RQLX:[
		{key:'仓体',text:'仓体'},
		{key:'罐体',text:'罐体'},
	],
	//物料类型
	LIST_WLLX:[
		{key:'0',text:'定量出料'},
		{key:'1',text:'单料线自动饲喂'},
		{key:'2',text:'定量入料'},
	],
	//阀门类型
	LIST_FMLX:[
		{key:'0',text:'单控'},
		{key:'1',text:'双控'},
	],
	//传感器类型
	LIST_CGLX:[
		{key:'8',text:'0_数字称重1'},
		{key:'11',text:'1_数字称重2'},
		{key:'10',text:'2_1-8模拟称重'},
		{key:'0',text:'3_1-4模拟称重'},
		{key:'1',text:'4_数字传感器1'},
		{key:'12',text:'5_数字传感器2'},
		{key:'2',text:'6_耀华传感器'},
		{key:'3',text:'7_I型超声波液位传感器'},
		{key:'4',text:'8_II型超声波液位传感器'},
		{key:'5',text:'9_重锤液位传感器'},
		{key:'6',text:'10_雷达液位计'},
		{key:'7',text:'11_雷达物位计'},
		{key:'9',text:'12_永基数字称重'},
	],
	//设备DTU类型
	LIST_DTULX:[
		{key:'1',text:'1_昌信3168数字'},
		{key:'2',text:'2_耀华3190(A9+DS3)'},
		{key:'3',text:'3_宏牧人19S'},
		{key:'4',text:'4_滨海永达D10'},
		{key:'5',text:'5_托利多'},
		{key:'6',text:'6_金钟'},
		{key:'7',text:'7_A12'},
		{key:'8',text:'8_A27'},
		{key:'9',text:'9_柯力XK3118K9'},
		{key:'10',text:'10_3190-A12'},
		{key:'11',text:'11_备用11'},
		{key:'12',text:'12_备用12'},
		{key:'13',text:'13_备用13'},
		{key:'51',text:'51_格林空压机'},
	],
	SYS_USER_TYPE:[
		{key:1,text:'管理员'},
		{key:2,text:'普通用户'},
		{key:3,text:'超级管理员'},
		{key:21,text:'送料员'},
	],
	LIST_SEX:[
		{key:'男',text:'男'},
		{key:'女',text:'女'},
	],
	//政治面貌
	LIST_POLITICS:[
		{key:'1',text:'群众'},
		{key:'2',text:'团员'},
		{key:'3',text:'党员'},
	],
	//学籍状态
	LIST_SCHOOL:[
		{key:1,text:'在读'},
		{key:2,text:'毕业'},
		{key:3,text:'休学'},
		{key:4,text:'退学'},
		{key:5,text:'其他'},
	],
	//省份
	LIST_PROVINCE:[
		{key:'110000',text:'北京市'},
		{key:'120000',text:'天津市'},
		{key:'130000',text:'河北省'},
		{key:'140000',text:'山西省'},
		{key:'150000',text:'内蒙古自治区'},
		{key:'210000',text:'辽宁省'},
		{key:'220000',text:'吉林省'},
		{key:'230000',text:'黑龙江省'},
		{key:'310000',text:'上海市'},
		{key:'320000',text:'江苏省'},
		{key:'330000',text:'浙江省'},
		{key:'340000',text:'安徽省'},
		{key:'350000',text:'福建省'},
		{key:'360000',text:'江西省'},
		{key:'370000',text:'山东省'},
		{key:'410000',text:'河南省'},
		{key:'420000',text:'湖北省'},
		{key:'440000',text:'广东省'},
		{key:'450000',text:'广西壮族自治区'},
		{key:'460000',text:'海南省'},
		{key:'500000',text:'重庆市'},
		{key:'510000',text:'四川省'},
		{key:'520000',text:'贵州省'},
		{key:'530000',text:'云南省'},
		{key:'540000',text:'西藏自治区'},
		{key:'610000',text:'陕西省'},
		{key:'620000',text:'甘肃省'},
		{key:'630000',text:'青海省'},
		{key:'640000',text:'宁夏回族自治区'},
		{key:'650000',text:'新疆维吾尔自治区'},
		{key:'710000',text:'台湾省'},
		{key:'810000',text:'香港特别行政区'},
		{key:'820000',text:'澳门特别行政区'},
	],
	//户口性质
	LIST_NATIVE:[
		{key:'1',text:'城镇'},
		{key:'2',text:'农村'},
	],
	//56个民族
	LIST_NATION:[
		{key:'01',text:'汉族'},
		{key:'02',text:'蒙古族'},
		{key:'03',text:'回族'},
		{key:'04',text:'藏族'},
		{key:'05',text:'维吾尔族'},
		{key:'06',text:'苗族'},
		{key:'07',text:'彝族'},
		{key:'08',text:'壮族'},
		{key:'09',text:'布依族'},
		{key:'10',text:'朝鲜族'},
		{key:'11',text:'满族'},
		{key:'12',text:'侗族'},
		{key:'13',text:'瑶族'},
		{key:'14',text:'白族'},
		{key:'15',text:'土家族'},
		{key:'16',text:'哈尼族'},
		{key:'17',text:'哈萨克族'},
		{key:'18',text:'傣族'},
		{key:'19',text:'黎族'},
		{key:'20',text:'傈僳族'},
		{key:'21',text:'佤族'},
		{key:'22',text:'畲族'},
		{key:'23',text:'高山族'},
		{key:'24',text:'拉祜族'},
		{key:'25',text:'水族'},
		{key:'26',text:'东乡族'},
		{key:'27',text:'纳西族'},
		{key:'28',text:'景颇族'},
		{key:'29',text:'柯尔克孜族'},
		{key:'30',text:'土族'},
		{key:'31',text:'达斡尔族'},
		{key:'32',text:'仫佬族'},
		{key:'33',text:'羌族'},
		{key:'34',text:'布朗族'},
		{key:'35',text:'撒拉族'},
		{key:'36',text:'毛南族'},
		{key:'37',text:'仡佬族'},
		{key:'38',text:'锡伯族'},
		{key:'39',text:'阿昌族'},
		{key:'40',text:'普米族'},
		{key:'41',text:'塔吉克族'},
		{key:'42',text:'怒族'},
		{key:'43',text:'乌孜别克族'},
		{key:'44',text:'俄罗斯族'},
		{key:'45',text:'鄂温克族'},
		{key:'46',text:'德昂族'},
		{key:'47',text:'保安族'},
		{key:'48',text:'裕固族'},
		{key:'49',text:'京族'},
		{key:'50',text:'塔塔尔族'},
		{key:'51',text:'独龙族'},
		{key:'52',text:'鄂伦春族'},
		{key:'53',text:'赫哲族'},
		{key:'54',text:'门巴族'},
		{key:'55',text:'珞巴族'},
		{key:'56',text:'基诺族'}
	]
}

export default comData