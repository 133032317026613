import {ElMessage} from 'element-plus'


const tips = class{
   constructor(message,type='success',onClose){
       this.message = message;
       this.type=type;
       this.onClose=onClose;
       this.mess_age()
   }
   mess_age(){
    ElMessage({
        message:this.message,
        type:this.type,
        onClose:this.onClose,
        duration:1500
    })
   }
}

export default tips

