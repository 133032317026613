// 放置所有的请求接口地址


 
 
const config = {
	token: 'sntoken', //token 存储于本地的标识,
	getToken: function() {
		return "Bearer "+localStorage.getItem(this.token)
	},
}

export default config