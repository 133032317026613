   import {createRouter,createWebHashHistory} from "vue-router"

   const routes=[
    {
        path:'/',
        name:'loginPage',
        component:()=>import(/*webpackChunkName:'loginPage'*/ '@/page/login/loginPage.vue')
    },{
      path:'/testHansonTable',
      name:'testHansonTable',
      component:()=>import(/*webpackChunkName:'testHansonTable'*/ '@/page/test/testHansonTable.vue') ,
    },{
      path:'/testHansonTable1',
      name:'testHansonTable1',
      component:()=>import(/*webpackChunkName:'testHansonTable1'*/ '@/page/test/testHansonTable1.vue') ,
    },{
      path:'/indexStockPage',
      name:'indexStockPage',
      component:()=>import(/*webpackChunkName:'indexStockPage'*/ '@/page/index/indexStockPage.vue'),
      redirect:'/stockTransactionMine_list',
      meta: {
        title: "首页"
      },
      children:[
        {
          path:'/stockHasSaled_list',
          name:'stockHasSaled_list',
          component:()=>import(/*webpackChunkName:'stockHasSaled_list'*/ '@/page/stock/stockHasSaled_list.vue'),
          meta: {
            title: "已经清仓股票"
          },
        },
        {
            path:'/stockHis_list',
            name:'stockHis_list',
            component:()=>import(/*webpackChunkName:'sysRole'*/ '@/page/stock/stockHis_list.vue'),
            meta: {
              title: "历史记录"
            },
        },
        {
            path:'/stockTransactionMine_list',
            name:'stockTransactionMine_list',
            component:()=>import(/*webpackChunkName:'sysRole'*/ '@/page/stock/stockTransactionMine_list.vue'),
            meta: {
              title: "买卖记录"
            },
        },{
          path:'/stockTransactionMine_list_has',
          name:'stockTransactionMine_list_has',
          component:()=>import(/*webpackChunkName:'sysRole'*/ '@/page/stock/stockTransactionMine_list_has.vue'),
          meta: {
            title: "当前持仓"
          }
        },
        {
            path:'/stockTransactionImitate_list',
            name:'stockTransactionImitate_list',
            component:()=>import(/*webpackChunkName:'sysRole'*/ '@/page/stock/stockTransactionImitate_list.vue'),
            meta: {
              title: "模拟记录"
            },
        },
        {
            path:'/stockInfor_list',
            name:'stockInfor_list',
            component:()=>import(/*webpackChunkName:'sysRole'*/ '@/page/stock/stockInfor_list.vue'),
            meta: {
              title: "股票信息"
            },
        },
        {
            path:'/stockSelect_list',
            name:'stockSelect_list',
            component:()=>import(/*webpackChunkName:'stockSelect_list'*/ '@/page/stock/stockSelect_list.vue'),
            meta: {
              title: "选股监控"
            },
        },{
          path:'/stockTransactionMine_state',
            name:'stockTransactionMine_state',
            component:()=>import(/*webpackChunkName:'stockTransactionMine_state'*/ '@/page/stock/stockTransactionMine_state.vue'),
            meta: {
              title: "股票信息"
            },

        },{
          path:'/stockMathResult_list',
            name:'stockMathResult_list',
            component:()=>import(/*webpackChunkName:'sysRole'*/ '@/page/stock/stockMathResult_list.vue'),
            meta: {
              title: "计算结果"
            },
        },{
          path:'/stockMathMain_list',
            name:'stockMathMain_list',
            component:()=>import(/*webpackChunkName:'sysRole'*/ '@/page/stock/stockMathMain_list.vue'),
            meta: {
              title: "计算"
            },
        },{
          path:'/stockParams_list',
          name:'stockParams_list',
          component:()=>import(/*webpackChunkName:'sysRole'*/ '@/page/stock/stockParams_list.vue'),
          meta: {
            title: "参数设置"
          },
        }
    ]
  },{
        path:'/indexStudentPage',
        name:'indexStudentPage',
        component:()=>import(/*webpackChunkName:'indexStudentPage'*/ '@/page/index/indexStudentPage.vue'),
        redirect:'/studentInfor_index',
        meta: {
          title: "首页"
        },
        children:[
          {
            path:'/studentInfor_list',
            name:'studentInfor_list',
            component:()=>import(/*webpackChunkName:'studentInfor_list'*/ '@/page/student/studentInfor_list.vue'),
            meta: {
              title: "用户管理"
            },
        },{
          path:'/studentInfor_index',
          name:'studentInfor_index',
          component:()=>import(/*webpackChunkName:'studentInfor_index'*/ '@/page/student/studentInfor_index.vue'),
          meta: {
            title: "统计概况"
          },
      }
      ]
    },
    {
      path:'/indexPage',
      name:'indexPage',
      component:()=>import(/*webpackChunkName:'indexPage'*/ '@/page/index/indexPage.vue') ,
      redirect:'/sysRole_list',
      meta: {
        title: "首页"
      },
      children:[
        {
          path:'/sysUser_list',
          name:'sysUser_list',
          component:()=>import(/*webpackChunkName:'sysMenu'*/ '@/page/sys/sysUser_list.vue'),
          meta: {
            title: "用户管理"
          },
      },
        {
          path:'/sysRole_list',
          name:'sysRole_list',
          component:()=>import(/*webpackChunkName:'sysRole'*/ '@/page/sys/sysRole_list.vue'),
          meta: {
            title: "角色管理"
          },
       }, {
          path:'/sysLog_list',
          name:'sysLog_list',
          component:()=>import(/*webpackChunkName:'sysRole'*/ '@/page/sys/sysLog_list.vue'),
          meta: {
            title: "日志管理"
          },
      },
      {
          path:'/sysDept_list',
          name:'sysDept_list',
          component:()=>import(/*webpackChunkName:'sysRole'*/ '@/page/sys/sysDept_list.vue'),
          meta: {
            title: "部门管理"
          },
      },
      {
          path:'/appFeedback_list',
          name:'appFeedback_list',
          component:()=>import(/*webpackChunkName:'sysRole'*/ '@/page/app/appFeedback_list.vue'),
          meta: {
            title: "意见反馈"
          },
      },
      {
          path:'/task_list',
          name:'task_list',
          component:()=>import(/*webpackChunkName:'sysRole'*/ '@/page/task/task_list.vue'),
          meta: {
            title: "消息管理"
          },
      },{
          path:'/companyUser_list',
          name:'companyUser_list',
          component:()=>import(/*webpackChunkName:'sysRole'*/ '@/page/company/companyUser_list.vue'),
          meta: {
            title: "企业用户"
          },
      },{
        path:'/company_list',
        name:'company_list',
        component:()=>import(/*webpackChunkName:'sysRole'*/ '@/page/company/company_list.vue'),
        meta: {
          title: "企业管理"
        },
    },{
        path:'/company_list_manage',
        name:'company_list_manage',
        component:()=>import(/*webpackChunkName:'sysRole'*/ '@/page/company/company_list_manage.vue'),
        meta: {
          title: "企业用户"
        },
      },{
        path:'/stockParams_list',
        name:'stockParams_list',
        component:()=>import(/*webpackChunkName:'sysRole'*/ '@/page/stock/stockParams_list.vue'),
        meta: {
          title: "参数设置"
        },
      },{
        path:'/deviceVersion_list',
        name:'deviceVersion_list',
        component:()=>import(/*webpackChunkName:'sysRole'*/ '@/page/device/deviceVersion_list.vue'),
        meta: {
          title: "升级版本"
        },
      },{
        path:'/deviceAfterSalesApply_list',
        name:'deviceAfterSalesApply_list',
        component:()=>import(/*webpackChunkName:'deviceAfterSalesApply_list'*/ '@/page/device/deviceAfterSalesApply_list.vue'),
        meta: {
          title: "售后申请"
        },
      },{
        path:'/deviceInfor_list',
        name:'deviceInfor_list',
        component:()=>import(/*webpackChunkName:'deviceInfor_list'*/ '@/page/device/deviceInfor_list.vue'),
        meta: {
          title: "设备列表"
        },
      },{
        path:'/deviceInfor_saveMore',
        name:'deviceInfor_saveMore',
        component:()=>import(/*webpackChunkName:'deviceInfor_saveMore'*/ '@/page/device/deviceInfor_saveMore.vue'),
        meta: {
          title: "设备列表"
        },
      },{
        path:'/deviceInfor_real_22',
        name:'deviceInfor_real_22',
        component:()=>import(/*webpackChunkName:'deviceInfor_real_22'*/ '@/page/device/deviceInfor_real_22.vue'),
        meta: {
          title: "实时数据"
        },
      },{
        path:'/deviceOpLog_list',
        name:'deviceOpLog_list',
        component:()=>import(/*webpackChunkName:'deviceOpLog_list'*/ '@/page/device/deviceOpLog_list.vue'),
        meta: {
          title: "操作日志"
        },
      },{
        path:'/deviceConLog_list',
        name:'deviceConLog_list',
        component:()=>import(/*webpackChunkName:'deviceConLog_list'*/ '@/page/device/deviceConLog_list.vue'),
        meta: {
          title: "连接日志"
        },
      },{
        path:'/deviceIps_list',
        name:'deviceIps_list',
        component:()=>import(/*webpackChunkName:'deviceIps_list'*/ '@/page/device/deviceIps_list.vue'),
        meta: {
          title: "IP地址"
        },
      },{
        path:'/devicePutLog_list',
        name:'devicePutLog_list',
        component:()=>import(/*webpackChunkName:'devicePutLog_list'*/ '@/page/device/devicePutLog_list.vue'),
        meta: {
          title: "入库日志"
        },
      },{
        path:'/devicePutLog_list',
        name:'devicePutLog_list',
        component:()=>import(/*webpackChunkName:'devicePutLog_list'*/ '@/page/device/devicePutLog_list.vue'),
        meta: {
          title: "入库日志"
        },
      },{
        path:'/deviceSimState_list',
        name:'deviceSimState_list',
        component:()=>import(/*webpackChunkName:'deviceSimState_list'*/ '@/page/device/deviceSimState_list.vue'),
        meta: {
          title: "SIM卡状态"
        },
      },{
        path:'/deviceTransfer_list',
        name:'deviceTransfer_list',
        component:()=>import(/*webpackChunkName:'deviceTransfer_list'*/ '@/page/device/deviceTransfer_list.vue'),
        meta: {
          title: "设备转移"
        },
      },{
        path:'/deviceUseLog_list',
        name:'deviceUseLog_list',
        component:()=>import(/*webpackChunkName:'deviceTransfer_list'*/ '@/page/device/deviceUseLog_list.vue'),
        meta: {
          title: "使用日志"
        },
      },{
        path:'/deviceVariable_list',
        name:'deviceVariable_list',
        component:()=>import(/*webpackChunkName:'deviceVariable_list'*/ '@/page/device/deviceVariable_list.vue'),
        meta: {
          title: "变量"
        },
      },{
        path:'/deviceAlarm_list',
        name:'deviceAlarm_list',
        component:()=>import(/*webpackChunkName:'deviceAlarm_list'*/ '@/page/device/deviceAlarm_list.vue'),
        meta: {
          title: "报警"
        },
      },{
        path:'/deviceAlarmType_list',
        name:'deviceAlarmType_list',
        component:()=>import(/*webpackChunkName:'deviceAlarmType_list'*/ '@/page/device/deviceAlarmType_list.vue'),
        meta: {
          title: "报警类型"
        },
      },{
        path:'/product_list',
        name:'product_list',
        component:()=>import(/*webpackChunkName:'product_list'*/ '@/page/device/product_list.vue'),
        meta: {
          title: "产品管理"
        },
      },{
        path:'/breedRecord_list',
        name:'breedRecord_list',
        component:()=>import(/*webpackChunkName:'breedRecord_list'*/ '@/page/breed/breedRecord_list.vue'),
        meta: {
          title: "养殖管理"
        },
      },{
        path:'/deviceInfor_list_card',
        name:'deviceInfor_list_card',
        component:()=>import(/*webpackChunkName:'product_list'*/ '@/page/device/deviceInfor_list_card.vue'),
        meta: {
          title: "可视化"
        },
      },{
        path:'/deviceInfor_index',
        name:'deviceInfor_index',
        component:()=>import(/*webpackChunkName:'deviceInfor_index'*/ '@/page/device/deviceInfor_index.vue'),
        meta: {
          title: "可视化1"
        },
      },{
        path:'/deviceInfor_index2',
        name:'deviceInfor_index2',
        component:()=>import(/*webpackChunkName:'deviceInfor_index2'*/ '@/page/device/deviceInfor_index2.vue'),
        meta: {
          title: "可视化2"
        },
      },{
        path:'/breedRecord_card',
        name:'breedRecord_card',
        component:()=>import(/*webpackChunkName:'breedRecord_card'*/ '@/page/breed/breedRecord_card.vue'),
        meta: {
          title: "可视化3"
        },
      },{
        path:'/breedRecord_look/:recordId',
        name:'breedRecord_look',
        component:()=>import(/*webpackChunkName:'breedRecord_look'*/ '@/page/breed/breedRecord_look.vue'),
        meta: {
          title: "可视化4"
        },
      }


      ,{
        path:'/breedCheckLog_list',
        name:'breedCheckLog_list',
        component:()=>import(/*webpackChunkName:'breedCheckLog_list'*/ '@/page/breed/breedCheckLog_list.vue'),
        meta: {
          title: "可视化4"
        },
      },{
        path:'/breedRecordDay_list',
        name:'breedRecordDay_list',
        component:()=>import(/*webpackChunkName:'breedRebreedRecordDay_listcord_look'*/ '@/page/breed/breedRecordDay_list.vue'),
        meta: {
          title: "可视化4"
        },
      },{
        path:'/breedRecordDie_list',
        name:'breedRecordDie_list',
        component:()=>import(/*webpackChunkName:'breedRecordDie_list'*/ '@/page/breed/breedRecordDie_list.vue'),
        meta: {
          title: "可视化4"
        },
      },{
        path:'/breedStandard_list',
        name:'breedStandard_list',
        component:()=>import(/*webpackChunkName:'breedStandard_list'*/ '@/page/breed/breedStandard_list.vue'),
        meta: {
          title: "可视化4"
        },
      },{
        path:'/breedWeight_list',
        name:'breedWeight_list',
        component:()=>import(/*webpackChunkName:'breedWeight_list'*/ '@/page/breed/breedWeight_list.vue'),
        meta: {
          title: "可视化4"
        },
      }
 
      ]
    }
   ]


   const router = createRouter({
      // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
      history: createWebHashHistory(),
      linkActiveClass:'active',
      routes, // `routes: routes` 的缩写
   })


  export default router

