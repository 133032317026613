import axios from 'axios'
import { Base64 } from 'js-base64';
import {ElMessageBox} from 'element-plus'

let instance  = axios.create({
    responseType:'json',
    headers:{'content-Type':'application/json'}
})

function baseFun(){
    const token = localStorage.getItem("sntoken");
    // const base64 = Base64.encode(token+":")
     return 'Bearer '+token
    // return token
}


instance.interceptors.request.use(
    config =>{
       
        let token = localStorage.getItem('sntoken')
        if(token){
            config.headers.Authorization = baseFun()
            config.headers.sntoken = baseFun()
        }
        return config;
    },
    err =>{
        return Promise.reject(err)
    }
)

instance.interceptors.response.use(
    response =>{
        if(response.data.code===401){
            ElMessageBox.alert(response.data.msg,'操作失败',{
                confirmButtonText:'确定',
                type:'error'
            }).then(res=>{
                localStorage.clear()
                window.location.href='/'
            })
        }else if(response.data.code===200){
            //正常进行 
        }else if(response.data.status!=200){
            if(response.data.msg != null && response.data.msg.trim() != ''){
                ElMessageBox.alert(response.data.msg,'操作失败',{
                    confirmButtonText:'确定',
                    type:'error'
                }).then(res=>{
                    return
                })
            }
            
        }
        return response
    },
    err =>{
        if(err.response){
            console.log(err);
            let errs = err.response.status
            let msg = err.response.data.msg.msg
            let errDate = errs==401 ?msg:'您没有权限访问'
            console.log("====="+errs);
            switch(errs){
                case 401:
                    ElMessageBox.alert(errDate,'您没有权限访问',{
                        confirmButtonText:'确定',
                        type:'error'

                    }).then(res=>{
                        console.log(res)
                        window.location.href='/'
                    }).catch(err=>{
                        console.log(err)
                        window.location.href='/'
                    })
                    break;

            }
        }
         return Promise.reject(err.response.data)//返回接口的错误信息
    }
)

export default instance




